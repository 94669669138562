<!-- copy of areas -->

<template>
	<div class="content-wrapper">
		<h1 class="mb-5">Bundle</h1>
		<b-card class="mb-4">
			<b-row class="my-1">
				<b-col lg="3">
					<b-input-group size="sm" class="mb-2">
						<b-input-group-prepend is-text>
							<b-icon icon="search"></b-icon>
						</b-input-group-prepend>
						<b-form-input
							id="filter-input"
							v-model="filter"
							type="search"
							placeholder="Search"
						></b-form-input>
					</b-input-group>
				</b-col>

				<b-col class="text-lg-right">
					<b-button
						size="sm"
						class="mb-2"
						@click="setupSidebar({})"
						v-b-toggle.sidebar-product
					>
						<b-icon icon="plus" aria-hidden="true"></b-icon> New Option
					</b-button>
				</b-col>
			</b-row>
		</b-card>

		<b-overlay rounded="sm" class="overlay-placeholder">
			<div v-if="loadingItems" class="w-100">
				<SkeletonTable></SkeletonTable>
			</div>
			<b-card v-else>
				<div v-if="loadingItems || loadingProducts" class="w-100">
					Loading...
				</div>
				<b-table
					v-else
					hover
					:items="filteredBundles"
					:fields="fields"
					:current-page="currentPage"
					:per-page="perPage"
					:filter="filter"
				>
					<template #cell(immagineBoundle)="row">
						<div>
							<img style="max-width:110px" :src="row.item.immagineBoundle" />
						</div>
					</template>

					<template #cell(prodottiBundle)="row">
						<span>{{ calcProdottiBundle(row.item.prodottiBundle) }}</span>
					</template>

					<template #cell(actions)="row">
						<b-dropdown
							size="sm"
							variant="link"
							toggle-class="text-decoration-none"
							no-caret
						>
							<template #button-content>
								<b-icon-gear-fill></b-icon-gear-fill>
							</template>
							<b-dropdown-item
								href="#"
								v-b-toggle.sidebar-product
								@click="setupSidebar(row.item)"
								>Edit</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								v-if="row.item.attiva"
								@click="row.item.attiva = !row.item.attiva"
								>Block</b-dropdown-item
							>
							<b-dropdown-item
								href="#"
								@click="
									currentItem = row.item;
									showDeleteModal = true;
								"
								>Delete</b-dropdown-item
							>
						</b-dropdown>
					</template>
				</b-table>
				<b-pagination
					v-model="currentPage"
					:total-rows="totalRows"
					:per-page="perPage"
					align="fill"
					size="sm"
					class="mt-4 mb-1"
				></b-pagination>
			</b-card>
		</b-overlay>
		<b-sidebar
			class="right-sidebar"
			id="sidebar-product"
			right
			shadow
			lazy
			@hidden="resetData"
		>
			<div class="content-wrapper  my-3 mx-1">
				<h4 v-if="tmpItem && tmpItem.nome">
					{{ tmpItem.nome }}
				</h4>
				<h4 v-else>New Bundle</h4>

				<b-row>
					<b-col>
						<div
							class="upload-photo-cicrle mt-2 mb-4"
							v-bind:style="{
								backgroundImage: 'url(' + backgroundImage() + ')',
							}"
						>
							<b-form-file
								class="button-file photo-button"
								v-model="tmpImage"
								:state="Boolean(tmpImage)"
								accept="image/*"
							>
								<template #placeholder>
									<b-icon-camera-fill />
								</template>
								<template #file-name>
									<b-icon-camera-fill />
								</template>
							</b-form-file>
							<b-icon
								v-if="loadingFile"
								class="loading-file"
								icon="arrow-clockwise"
								animation="spin"
								font-scale="2"
							></b-icon>
						</div>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group>
							<b-form-checkbox v-model="tmpItem.attivo">
								Attivo
							</b-form-checkbox>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Offerta Associata">
							<b-form-select
								:options="offerteOptions"
								v-model="tmpItem.idOffertaAssociata"
							></b-form-select>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Nome Bundle">
							<b-form-input v-model="tmpItem.nomeBoundle" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Descrizione ">
							<b-form-textarea
								v-model="tmpItem.descrizione"
								rows="3"
								max-rows="6"
							></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Codice interno">
							<b-form-input v-model="tmpItem.codiceInterno" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Tipi Cliente Abilitati ">
							<b-form-select
								v-model="tmpItem.idClientTypesAbilitati"
								multiple
								:options="clientiOptions"
							></b-form-select>
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-form-group label="Prezzo">
							<b-form-input type="number" v-model="tmpItem.prezzo" />
						</b-form-group>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<b-row>
							<b-col><h4 class="mt-4">Prodotti</h4></b-col>
						</b-row>
						<b-row align-v="center">
							<b-col>
								<b-form-group label="Cerca">
									<b-form-input v-model="filterItems" />
								</b-form-group>
							</b-col>
						</b-row>
						<b-row
							v-for="(prodotto, index) in filteredItems"
							:key="index"
							align-v="center"
						>
							<b-col>
								<b-form-group label="Nome Prodotto">
									<b-form-input
										disabled
										type="text"
										:value="prodotto.nomeProdotto"
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group label="Quantita">
									<b-form-input
										type="number"
										v-model="prodotto.quantita"
									></b-form-input>
								</b-form-group>
							</b-col>
							<b-col>
								<b-form-group>
									<b-form-checkbox v-model="prodotto.visibleApp"
										>Visibile in app</b-form-checkbox
									>
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>

				<b-row>
					<b-col>
						<div class="mt-5 mb-5">
							<b-button
								v-if="loadingItems"
								squared
								variant="primary"
								size="lg"
								class="big-button"
								disabled
								>Salvataggio in corso...</b-button
							>
							<b-button
								v-else
								squared
								variant="primary"
								size="lg"
								class="big-button"
								@click="saveItem"
								>Confirm</b-button
							>
						</div>
					</b-col>
				</b-row>
			</div>
		</b-sidebar>
		<b-modal
			v-model="showDeleteModal"
			title="Attention!"
			ok-title="Confirm"
			cancel-title="Cancel"
			@hidden="resetData"
			@ok="deleteItem(currentItem.id)"
		>
			<span v-if="currentItem">Do you really want to delete?</span>
		</b-modal>
	</div>
</template>

<script>
	import AxiosService from "@/axiosServices/AxiosService.js";
	import SkeletonTable from "../../components/SkeletonTable.vue";
	export default {
		name: "BundlesYoube",
		components: { SkeletonTable },
		data() {
			return {
				filterItems: "",
				loadingItems: false,
				items: [],
				fields: [
					{ key: "idOffertaAssociata", label: "ID Offerta Associata" },
					{ key: "immagineBoundle", label: "Immagine Bundle" },
					{ key: "nomeBoundle", label: "Nome Bundle" },
					{ key: "descrizione", label: "Descrizione" },
					{ key: "prezzo", label: "Prezzo" },
					{ key: "codiceInterno", label: "Codice Interno" },
					{ key: "prodottiBundle", label: "Prodotti Bundle" },
					{ key: "attivo", label: "Attivo" },
					{ key: "actions", label: "Azioni" },
				],
				view_lang: null,

				currentPage: 1,
				perPage: 10,
				filter: "",

				tmpItem: {
					id: 0,
					idOffertaAssociata: 0,
					immagineBoundle: "",
					nomeBoundle: "",
					descrizione: "",
					prezzo: 0,
					codiceInterno: "",
					idClientTypesAbilitati: [],
					prodottiBundle: [],
					attivo: true,
				},
				currentItem: {},

				tmpImage: null,

				showDeleteModal: false,

				loadingProducts: false,
				productsOptions: [],

				loadingOffers: false,
				offerteOptions: [],

				loadingClienti: false,
				loadingFile: false,
				clientiOptions: [],
			};
		},
		serviceGET: null,
		servicePOST: null,
		servicePUT: null,
		serviceDELETE: null,
		created() {
			this.serviceGET = new AxiosService("Ecommerce/GetBundles");
			this.servicePOST = new AxiosService("Ecommerce/AddBundle");
			this.servicePUT = new AxiosService("Ecommerce/EditBundle");
			this.serviceDELETE = new AxiosService("Ecommerce/DeleteBundle");

			this.resetData();

			this.getData();
			this.getProducts();
			this.getOfferte();
			this.getTipiCliente();
		},
		mounted() {
			// this.service.read().then((data) => {
			//   this.items = data;
			// });
			// this.service
			//   .getOptions("Company")
			//   .then((data) => (this.companyList = data));
		},
		methods: {
			calcProdottiBundle(prodottiBundle) {
				console.log(
					"🚀 ~ file: Bundles.vue:362 ~ calcProdottiBundle ~ prodottiBundle:",
					prodottiBundle
				);
				let nProdotti = 0;
				prodottiBundle.forEach((prodotto) => {
					nProdotti += prodotto.quantita;
				});
				return nProdotti;
			},
			getTipiCliente() {
				this.loadingClienti = true;
				const service = new AxiosService("Ecommerce/GetClientTypes");
				service
					.read()
					.then((res) => {
						res.forEach((offerta) => {
							this.clientiOptions.push({
								text: offerta.nome,
								value: offerta.id,
							});
						});
					})
					.finally(() => {
						this.loadingClienti = false;
					});
			},
			getOfferte() {
				this.loadingOffers = true;
				const service = new AxiosService("Ecommerce/GetOffers");
				service
					.read()
					.then((res) => {
						res.forEach((offerta) => {
							this.offerteOptions.push({
								text: offerta.nome,
								value: offerta.id,
							});
						});
					})
					.finally(() => {
						this.loadingOffers = false;
					});
			},
			setupSidebar(itemFromTable) {
				console.log(
					"🚀 ~ file: Bundles.vue:403 ~ setupSidebar ~ itemFromTable:",
					itemFromTable
				);
				this.currentItem = itemFromTable;
				this.tmpItem = { ...itemFromTable };
				this.tmpItem.prodottiBundle = [...this.productsOptions];

				for (let i = 0; i < this.tmpItem.prodottiBundle.length; i++) {
					for (let j = 0; j < this.currentItem.prodottiBundle.length; j++) {
						if (
							this.tmpItem.prodottiBundle[i].idProdotto ==
							this.currentItem.prodottiBundle[j].idProdotto
						) {
							this.tmpItem.prodottiBundle[
								i
							].quantita = this.currentItem.prodottiBundle[j].quantita;
							this.tmpItem.prodottiBundle[
								i
							].visibleApp = this.currentItem.prodottiBundle[j].visibleApp;
						}
					}
				}
			},
			getProducts() {
				this.loadingProducts = true;
				let service = new AxiosService("Ecommerce/GetProducts");
				service
					.read()
					.then((res) => {
						res.forEach((element) => {
							this.productsOptions.push({
								idProdotto: element.id,
								quantita: null,
								visibleApp: false,
								nomeProdotto: element.nomeProdotto,
							});
						});
					})
					.finally(() => {
						this.loadingProducts = false;
					});
			},
			getData() {
				this.loadingItems = true;
				this.items.splice(0);
				this.resetData();

				this.serviceGET
					.read()
					.then((res) => {
						this.items = res;
					})
					.finally(() => {
						this.loadingItems = false;
					});
			},
			resetData() {
				this.tmpItem = {
					id: 0,
					idOffertaAssociata: 0,
					immagineBoundle: "",
					nomeBoundle: "",
					descrizione: "",
					prezzo: 0,
					idClientTypesAbilitati: [],
					prodottiBundle: [],
					attivo: true,
				};
			},
			async saveItem() {
				this.loadingItems = true;
				this.loadingFile = true;
				let item = { ...this.tmpItem };

				if (this.tmpImage) {
					if (this.tmpImage.size > 3 * 1024 * 1024) {
						alert("I file non possono superare il massimo di 3MB");
						return;
					}
					const formData = new FormData();
					formData.append("pic", this.tmpImage);

					this.servicePOST
						.uploadFile(formData)
						.then((res) => {
							item.immagineBoundle = this.$driveAddress + res.data;
						})
						.finally(() => {
							this.loadingFile = false;
							if (item.id) {
								this.servicePUT
									.update(item)
									.then(() => {
										this.$root.$emit("bv::toggle::collapse", "sidebar-product");
										this.getData();
										this.$successToast();
									})
									.catch(() => {
										this.$errorToast();
										return false;
									})
									.finally(() => {
										this.loadingItems = false;
									});
							} else {
								this.servicePOST
									.create(item)
									.then((response) => {
										item.id = response.id;
										this.items.push(item);
										this.$root.$emit("bv::toggle::collapse", "sidebar-product");
										this.getData();
										this.$successToast();
									})
									.catch(() => {
										this.$errorToast();
										return false;
									})
									.finally(() => {
										this.loadingItems = false;
									});
							}
						});
				} else {
					if (item.id) {
						this.servicePUT
							.update(item)
							.then(() => {
								this.$root.$emit("bv::toggle::collapse", "sidebar-product");
								this.getData();
								this.$successToast();
							})
							.catch(() => {
								this.$errorToast();
								return false;
							})
							.finally(() => {
								this.loadingItems = false;
							});
					} else {
						this.servicePOST
							.create(item)
							.then((response) => {
								item.id = response.id;
								this.items.push(item);
								this.$root.$emit("bv::toggle::collapse", "sidebar-product");
								this.getData();
								this.$successToast();
							})
							.catch(() => {
								this.$errorToast();
								return false;
							})
							.finally(() => {
								this.loadingItems = false;
							});
					}
				}
			},
			backgroundImage() {
				return "../../../public/images/placeholder-product.jpg";
			},
			deleteItem(id) {
				this.loadingItems = true;
				this.serviceDELETE
					.delete(id)
					.then((response) => {
						if (response) {
							this.items = this.items.filter(
								(currentItem) => id !== currentItem.id
							);
							this.$successToast();
						} else {
							this.$errorToast();
							return false;
						}
					})
					.catch(() => {
						this.$errorToast();
						return false;
					})
					.finally(() => {
						this.loadingItems = false;
					});
			},
		},
		computed: {
			totalRows() {
				return this.filteredBundles ? this.filteredBundles.length : 0;
			},
			filterCompanyList() {
				return [{ value: null, text: "All companies" }, ...this.companyList];
			},

			filteredBundles() {
				if (this.items) {
					return this.items.filter((item) => {
						if (
							item.nomeBoundle.toLowerCase().includes(this.filter.toLowerCase())
						) {
							return true;
						}
					});
				} else {
					return [];
				}
			},
			filteredItems() {
				if (this.tmpItem.prodottiBundle) {
					return this.tmpItem.prodottiBundle.filter((item) => {
						if (
							item.nomeProdotto
								.toLowerCase()
								.includes(this.filterItems.toLowerCase())
						) {
							return true;
						}
					});
				} else {
					return [];
				}
			},
		},
		watch: {
			filter: {
				handler() {
					this.currentPage = 1;
				},
			},
		},
	};
</script>
